let baseurl = window.location.href;

if (baseurl.endsWith("/")) {
    baseurl = baseurl.substring(0, baseurl.length - 1);
}

export const msalConfig = {
    auth: {
        clientId: "958c6c51-f893-49b7-af97-0ca76d2ab25f",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: `${baseurl}/auth/redirect`,
    },
};
