import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";

import reportWebVitals from "./reportWebVitals";
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useNavigate,
    useRouteError,
} from "react-router-dom";
import Api, { BASE_ROUTE } from "./api";

import { Grid, Typography, Snackbar, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import UserContext, { UserProvider } from "./context/userContext";
import Dashboard, { dashboardLoader } from "./pages/dashboard/dashboard";
import Users, { usersLoader } from "./pages/users/userList";
import { Sidebar } from "./components/Sidebar";
import BmsTickets, { bmsCasesLoader } from "./pages/bms_tickets/bmsTickets";
import AdminDashboard, {
    adminDashboardLoader,
} from "./pages/dashboard/adminDashboard";
import Devices, { devicesLoader } from "./pages/devices/Devices";
import Licenses from "./pages/licenses/Licenses";
import Login from "./pages/auth/Login";
import { LoadingCenterButton } from "./components/Loading";
import UsersOrders, { usersOrdersLoader } from "./pages/users/userOrderList";

function Layout(props) {
    const navigate = useNavigate();
    const { setUserData, userData, snackbarText, setSnackbarText } =
        useContext(UserContext);

    useEffect(() => {
        setTimeout(() => {
            setSnackbarText("");
        }, 2000);
    }, [setSnackbarText, snackbarText]);

    useEffect(() => {
        const getUser = async () => {
            try {
                const res = await Api.get("/login");
                setUserData(res);
            } catch (e) {
                navigate("/login");
            }
        };
        if (userData == null) {
            getUser();
        }
    }, [navigate, setUserData, userData]);

    return (
        <>
            <Box display="flex" sx={{ height: "100vh" }}>
                <Box
                    width="12rem"
                    sx={{
                        position: "sticky",
                        top: 0,
                        height: "100vh",
                        overflow: "hidden",
                    }}
                >
                    <Sidebar />
                </Box>
                <Box flexGrow={1} className="layout_content">
                    <Outlet />
                </Box>
            </Box>
            <Snackbar
                open={snackbarText !== ""}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message={snackbarText}
            />
        </>
    );
}

/*


<Grid container spacing={0} alignContent={"stretch"}>
                <Grid item sx={{overflow:"hidden"}}>
                </Grid>
                <Grid item>
                    <Outlet />
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarText !== ""}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message={snackbarText}
            />

            */

function ErrorFallback(e) {
    const navigate = useNavigate();
    let error: any = useRouteError();

    useEffect(() => {
        if (error?.status === 403) {
            navigate("/login");
        }
    }, [error?.status, navigate]);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "90vh", textAlign: "center" }}
        >
            <Grid item>
                <ErrorOutlineIcon sx={{ fontSize: "4rem" }} />
                <Typography variant="h6">Something went wrong</Typography>
            </Grid>
        </Grid>
    );
}

function Protect(props: any) {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const [showChild, setShowChild] = useState(false);

    useEffect(() => {
        if (userData != null) {
            if (userData.is_dashboard_admin) {
                setShowChild(true);
            } else {
                navigate("/");
            }
        }
    }, [navigate, userData]);

    if (showChild) return props.children;
}

const router = createBrowserRouter([
    {
        element: <Login />,
        path: "login",
        errorElement: <ErrorFallback />,
    },
    {
        element: <Layout />,
        path: BASE_ROUTE,
        children: [
            {
                path: "",
                element: <Dashboard />,
                loader: dashboardLoader,
                errorElement: <ErrorFallback />,
            },
            {
                path: "dashboard",
                element: <AdminDashboard />,
                loader: adminDashboardLoader,
                errorElement: <ErrorFallback />,
            },
            {
                path: "users",
                element: (
                    <Protect>
                        <Users />
                    </Protect>
                ),
                loader: usersLoader,
                errorElement: <ErrorFallback />,
            },
            {
                path: "users/orders",
                element: (
                    <Protect>
                        <UsersOrders />
                    </Protect>
                ),
                loader: usersOrdersLoader,
                errorElement: <ErrorFallback />,
            },
            {
                path: "cases",
                element: (
                    <Protect>
                        <BmsTickets />
                    </Protect>
                ),
                loader: bmsCasesLoader,
                errorElement: <ErrorFallback />,
            },
            {
                path: "devices",
                element: (
                    <Protect>
                        <Devices />
                    </Protect>
                ),
                loader: devicesLoader,
                errorElement: <ErrorFallback />,
            },
            /*{
                path: "groups",
                element: <Groups />,
                errorElement: <ErrorFallback />,
            },*/
            {
                path: "licenses",
                element: (
                    <Protect>
                        <Licenses />
                    </Protect>
                ),
                errorElement: <ErrorFallback />,
            },
        ],
    },
    {
        path: "/*",
        element: <>Page not found</>,
    },
]);

const initializeMsal = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    return msalInstance;
};

function MsalWrapper(props) {
    const [msalInstance, setMsalInstance] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            const instance = await initializeMsal();
            setMsalInstance(instance);
        };
        initialize();
    }, []);

    if (!msalInstance) {
        return <LoadingCenterButton />;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                loadingComponent={LoadingCenterButton}
            >
                <UserProvider>
                    <RouterProvider router={router} />
                </UserProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root")).render(<MsalWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
