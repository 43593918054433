import Api from "../../api";
import {
    Button,
    Typography,
    Modal,
    Box,
    FormGroup,
    TextField,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { IDevice } from "../../interface";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import Paginator from "../../components/Paginator";
import style from "./Devices.module.css";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
export async function devicesLoader() {
    return Api.get("/devices/");
}

function DeviceModal({
    show,
    closeModal,
    device,
}: {
    show: boolean;
    closeModal: any;
    device: IDevice;
}) {
    if (!device) return;
    return (
        <Modal
            open={show}
            onClose={() => {
                closeModal();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={style.modal}>
                <Typography variant="h5">Enhetsdetaljer</Typography>
                <FormGroup
                    sx={{
                        display: "flex",
                        gap: 3,
                        marginTop: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    <TextField
                        disabled
                        label="Produkt"
                        variant="standard"
                        value={device.product}
                    />
                    <TextField
                        disabled
                        label="Produkt Navn"
                        variant="standard"
                        value={device.name}
                    />
                    <TextField
                        disabled
                        label="Produkt Type"
                        variant="standard"
                        value={device.product_type}
                    />
                    <TextField
                        disabled
                        label="Serienummer"
                        variant="standard"
                        value={device.serial_number}
                    />
                    <TextField
                        disabled
                        label="Operativ System"
                        variant="standard"
                        value={device.os}
                    />
                    <TextField
                        disabled
                        label="Operativ System Versjon"
                        variant="standard"
                        value={device.os_version}
                    />
                    <TextField
                        disabled
                        label="Eier"
                        variant="standard"
                        value={
                            device.user?.first_name +
                            " " +
                            device.user?.last_name
                        }
                    />
                </FormGroup>
                <Button disabled variant="outlined">
                    Delete device
                </Button>
            </Box>
        </Modal>
    );
}

export function DeviceTable(props: { result: IDevice[]; buttonClick?: any }) {
    if (props.result?.length === 0) {
        return (
            <div className={"iconPlaceholder"}>
                <DeviceUnknownIcon sx={{ transform: "scale(2)" }} />
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                    Ingen enheter
                </Typography>
            </div>
        );
    }

    return (
        <div className={style.tableWrapper}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Navn</th>
                        <th>Type</th>
                        <th>Eier</th>
                        <th className="tableCenter">Samsvarlig</th>
                        {props.buttonClick && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {props.result?.map((device: IDevice) => (
                        <tr key={device.id}>
                            <td>{device.product}</td>
                            <td>{device.name}</td>

                            <td>{device.product_type}</td>
                            <td>
                                {device.user.first_name} {device.user.last_name}
                            </td>
                            <td className="tableCenter">
                                {device.compliant ? (
                                    <WarningIcon color="warning" />
                                ) : (
                                    <CheckCircleIcon color="success" />
                                )}
                            </td>
                            {props.buttonClick != null && (
                                <td className="tableRight">
                                    <Button
                                        startIcon={<OpenInFullIcon />}
                                        size="small"
                                        onClick={() => {
                                            props.buttonClick(device);
                                        }}
                                    >
                                        Se mer
                                    </Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default function Devices() {
    const [showModal, setShowModal] = useState(false);
    const [activeDevice, setActiveDevice] = useState(null);

    const data: any = useLoaderData();
    const [result, setResult] = useState<IDevice[]>(data.results);

    return (
        <>
            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Enheter
                </Typography>
            </div>
            <div className="paddingPages">
                <DeviceModal
                    show={showModal}
                    device={activeDevice}
                    closeModal={() => setShowModal(false)}
                />

                <DeviceTable
                    result={result}
                    buttonClick={(device) => {
                        setActiveDevice(device);
                        setShowModal(true);
                    }}
                />

                <Paginator setResult={(_res) => setResult(_res)} />
            </div>
        </>
    );
}
