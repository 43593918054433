import style from "./bmsTickets.module.css";
import { TextField, Modal, Box, Grid, Button, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import Api from "../../api";
import "quill/dist/quill.core.css";
import UserContext from "../../context/userContext";

import QuillEditor from "../../components/QuillEditor";

export default function BmsSubmitModal(props: any) {
    const [title, setTitle] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { setSnackbarText } = useContext(UserContext);

    useEffect(() => {
        setErrorMsg("");
    }, [title, editorValue]);

    const handleEditorChange = (value) => {
        setEditorValue(value);
    };

    function resetForm() {
        setTitle("");
        setEditorValue("");
    }

    async function submitForm() {
        if (title?.length === 0) {
            setErrorMsg("Emnefeltet kan ikke være tomt");
            return;
        }
        if (editorValue?.length === 0) {
            setErrorMsg("Innholdsfeltet kan ikke være tomt");
            return;
        }

        const data = {
            title: title,
            content: editorValue,
        };
        setIsLoading(true)

        try {
            await Api.post("/cases/", data);
            setSnackbarText("Saken er opprettet");
        } catch (e) {
            setSnackbarText("Noe gikk galt");
        }finally{
            setIsLoading(false)
            props.closeModal(true);
        }
        resetForm();
    }

    useEffect(() => {
        setEditorValue(props.initContentHtml);
    }, [props?.initContentHtml]);

    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);

    return (
        <div>
            <Modal
                open={props.show}
                onClose={() => {
                    props.closeModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={style.modal}>
                    <Grid container direction={"column"} spacing={3}>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="Emne"
                                variant="standard"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <QuillEditor
                                value={editorValue}
                                initValue={props.initContentHtml}
                                onChange={handleEditorChange}
                            />
                        </Grid>
                        <Grid item sx={{ textAlign: "right" }}>
                            <Button onClick={submitForm} disabled={isLoading}>Send inn</Button>
                            {errorMsg && (
                                <p>
                                    <Typography variant="caption" color="red">
                                        {errorMsg}
                                    </Typography>
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
