import {
    List,
    ListSubheader,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import GroupIcon from "@mui/icons-material/Group";
import DevicesIcon from "@mui/icons-material/Devices";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useContext, useEffect, useState } from "react";
import UserContext from "../context/userContext";
import { useMsal } from "@azure/msal-react";
import style from "./Sidebar.module.css";
import Api from "../api";

function getSidebarItems(isAdmin: boolean) {
    return [
        {
            title: "Dashboard",
            links: [
                {
                    title: "Personlig",
                    path: "/",
                    icon: <AccountCircleIcon />,
                },
                {
                    title: "Bedrift",
                    path: "/dashboard",
                    icon: <DashboardIcon />,
                    disabled: !isAdmin,
                },
            ],
        },
        {
            title: "Ansatte",
            links: [
                {
                    title: "Brukere",
                    path: "/users",
                    icon: <GroupIcon />,
                    disabled: !isAdmin,
                },
                /*{
                    title: "Grupper",
                    path: "/groups",
                    icon: <GroupsIcon />,
                    disabled: !isAdmin
    
                },*/
                {
                    title: "Enheter",
                    path: "/devices",
                    icon: <DevicesIcon />,
                    disabled: !isAdmin,
                },
                {
                    title: "Support",
                    path: "/cases",
                    icon: <SupportAgentIcon />,
                    disabled: !isAdmin,
                },
                /*
                {
                    title: "Lisenser",
                    path: "/licenses",
                    icon: <TerminalIcon />,
                    disabled: !isAdmin
                },
                */
            ],
        },
        /*{
            title: "Linjer",
            links: [
                {
                    title: "Vis linjer",
                    path: "/dashboard",
                },
                {
                    title: "Bestill linjer",
                    path: "/dashboard",
                },
                
            ],
        },*/

        {
            title: "Konto",
            links: [
                /*{
                    title: "Min Profil",
                    path: "/dashboard",
                    icon: <PersonIcon />,
                },*/
                {
                    title: "Logg ut",
                    icon: <LogoutIcon />,
                },
            ],
        },
    ];
}

export function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState(false);
    const { instance } = useMsal();

    useEffect(() => {
        if (userData) {
            setIsAdmin(userData.is_dashboard_admin);
        }
    }, [userData]);

    async function handleLogout() {
        try {
            await Api.delete("/login");
        } catch (e) {}

        instance.logoutRedirect();
    }

    return (
        <div className={style.wrapper}>
            <div
                style={{ padding: "1rem 2rem 0rem 1rem", textAlign: "center" }}
            >
                <img
                    src={"/static/logo.png"}
                    alt="Logo"
                    style={{ width: "100%" }}
                ></img>
                <Typography variant="body2" color="text.secondary">
                    IT-Portal
                </Typography>
            </div>
            {getSidebarItems(isAdmin).map((item, index) => {
                return (
                    <List
                        key={index}
                        sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                            >
                                {item.title}
                            </ListSubheader>
                        }
                    >
                        {item.links.map((link, index) => {
                            return (
                                <ListItemButton
                                    key={index}
                                    disabled={link.disabled}
                                    onClick={() => {
                                        link.title === "Logg ut"
                                            ? handleLogout()
                                            : navigate(link.path);
                                    }}
                                    sx={{
                                        backgroundColor:
                                            location.pathname === link.path
                                                ? "#f5f5f5"
                                                : null,
                                    }}
                                >
                                    <ListItemIcon>
                                        {link.icon ? link.icon : <InboxIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={link.title} />
                                </ListItemButton>
                            );
                        })}
                    </List>
                );
            })}
        </div>
    );
}
